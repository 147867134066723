exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-special-tsx": () => import("./../../../src/pages/special.tsx" /* webpackChunkName: "component---src-pages-special-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-special-tsx": () => import("./../../../src/templates/special.tsx" /* webpackChunkName: "component---src-templates-special-tsx" */),
  "component---src-templates-works-tsx": () => import("./../../../src/templates/works.tsx" /* webpackChunkName: "component---src-templates-works-tsx" */)
}

